<template>
  <h1>Simple Reaction Timer</h1>
  <h3 v-if = 'showInstructions'>After clicking <b>START</b> a block will appear after a random amount of time. When it appears, click it as fast as you can. This will determine your reaction time in milliseconds.</h3>
  <button @click='startGame' :class = '{blocked: gameIsRunning === true}' :disabled = 'gameIsRunning'>{{buttonText}}</button>
  <Block v-if='gameIsRunning' :delay = 'delay' @BLOCK_CLICKED='stopGame'></Block>
  <Results v-if = 'showReactionTime' :reactionTime = 'reactionTime'></Results>
  <footer>
    
   <a class="bmc-button" target="_blank" href="https://www.buymeacoffee.com/azxycdev">
    <link href="https://fonts.googleapis.com/css?family=Cookie" rel="stylesheet">
    <img style = 'background-color:#FFDD00' src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg" alt="Buy Azxyc a coffee">
    <span style="margin-left:5px">Buy me a coffee</span>
  </a></footer>

</template>

<script>

  import Block from './components/Block.vue'
  import Results from './components/Results.vue'

export default {
  name: 'App',
  components: {
    Block,Results
    
  },
  data(){
    return{
      showInstructions: true,
      gameIsRunning: false,
      delay: null,
      buttonText: 'START',
      showReactionTime: false,
      reactionTime: null
    }
    

  },
  methods:{
    startGame(){
      this.showInstructions = false,
      this.gameIsRunning = true
      this.showReactionTime = false
      this.delay = 2000+Math.random()*5000
      this.buttonText = 'GET READY'


    },
    stopGame(reactionTime){
      this.showReactionTime = true
      this.gameIsRunning = false
      this.buttonText = 'TRY AGAIN'
      this.reactionTime = reactionTime
    }
  }
}
</script>

<style>

*{
  background: darkcyan;
  color: white;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#FFDD00;
  color: white;
  text-align: center;
}

a{
  text-decoration: none;
  background-color:#FFDD00;
  color: black;
}
p{
  background-color:#FFDD00;
  color: black;
}



button{
  border: none;
  background: white;
  color: darkcyan;
  font-weight: bolder;
  margin: 15px;
  padding: 20px;
  font-size: larger;
  border-radius: 20px;
  transition: .75s ease-in-out;
  cursor: pointer;

}
h3{
  font-weight: lighter;
  padding: 50px;
}


.blocked{
  background: crimson;
  border: solid 5px white;
  color: white;
  cursor: default;
}
.bmc-button>span {
  font-family:'Cookie', cursive !important;
  background-color:#FFDD00 !important;
  color: black;

}


.bmc-button img {
  border: none;

  width: 27px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  order: none !important;
  vertical-align: middle !important;
  }.bmc-button{line-height: 36px !important;
  height:37px !important;
  text-decoration: none !important;
  display:inline-flex !important;
  color:#333333 !important;
  background-color:#FFDD00 !important;
  border-radius: 3px !important;
  border: 1px solid transparent !important;
  padding: 1px 9px !important;
  font-size: 23px !important;
  letter-spacing:0.6px !important;
  
  margin: 0 auto !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -o-transition: 0.3s all linear !important;
  -webkit-transition: 0.3s all linear !important;
  -moz-transition: 0.3s all linear !important;
  -ms-transition: 0.3s all linear !important;
  transition: 0.3s all linear !important;
}
</style>
