<template>
	<div v-if = 'showBlock' class = 'block' @click = 'blockClicked'>
		{{blockText}}
	</div>
</template>

<script>
	export default{
		props:['delay'],
		mounted(){
			setTimeout(()=>{
				this.showBlock = true
				this.startTimer()
			}, this.delay)

		},

		data(){
			return{
				showBlock: false,
				blockText: 'CLICK NOW',
				startTime: null,
				endTime: null,
				reactionTime: null

			}
		},
		methods:{
			blockClicked(){
				this.stopTimer();
				this.showBlock = false
				this.$emit('BLOCK_CLICKED', this.reactionTime)

			},
			startTimer(){
				this.startTime = Date.now()

			},
			stopTimer(){
				this.endTime = Date.now()
				this.reactionTime = (this.endTime-this.startTime)

			}
		}

	}
</script>

<style scoped>

	.block{
		width: 75%;
		height: 10%;
		border-radius: 20px;
		border: solid 5px white;
		background: crimson;
		color: white;
		font-size: x-large;
		padding: 200px 0;
		margin: 40px auto;
		text-align: center;
		font-weight: bolder;
		cursor: pointer;
		transition: .2s ease-in-out;
	}
	
</style>