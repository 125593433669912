<template>
	<h2>Your reaction time is</h2>
  	<h1>{{reactionTime}}ms</h1>
  	<h1>{{message}}</h1><br/>
  	
</template>

<script>
	export default{
		props: ['reactionTime'],
		data(){
			return{
				message: ''
		}

		},
		mounted(){

			if (this.reactionTime < 140){
				this.message = 'Are you using an autoclicker?'
			}
			if (this.reactionTime > 100 && this.reactionTime < 200){
				this.message="Your reaction time is shorter than most people."
			}
			if(this.reactionTime > 200 && this.reactionTime < 300){
				this.message='Your reaction time is similar to most people.'
			}
			if(this.reactionTime > 300 && this.reactionTime < 400){
				this.message = 'Your reaction time is slightly longer than most people.'
			}
			if(this.reactionTime > 400 && this.reactionTime < 1800){
				this.message = 'Your reaction time is longer than most people.'
			}
			if(this.reactionTime > 1800){
				this.message='Remember to click the block as fast as possible!'
			}

			console.log(this.message)
	}

	}
	
</script>

<style scoped>
	img{
		border-radius: 30px;
		padding: 20px;
		max-width: 50%;
		border: solid 10px white;
		background: white;
	}
	
</style>